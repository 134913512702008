import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle: 'Loading...',
    roomState: {
      name: '',
      room: {},
      queue: [],
      votes: [],
      tracksInQueue: [],
      access: []
    },
    freezeState: false
  },
  mutations: {
    setTitle (state, title) {
      state.pageTitle = title
    },
    setStatus (state, status) {
      if (!state.freezeState) {
        var tracksInQueue = []
        status.queue.forEach((el) => {
          tracksInQueue.push(el.track_id)
        })

        status.tracksInQueue = tracksInQueue

        state.roomState = status
      }
    },
    voteForSong (state, entry_id) {
      state.roomState.votes.push(entry_id)
    },
    addSong (state, track_id) {
      state.roomState.queue_short.push(track_id)
      state.roomState.tracksInQueue.push(track_id)
      state.roomState.votes.push(track_id)
    },
    freeze: (state) => state.freezeState = true,
    unFreeze: (state) => state.freezeState = false,
  },
  actions: {
    vote ({ commit, state }, vote) {
      const currentState = state.roomState

      currentState.queue.forEach(el => {
        if (el.entry_id === vote.entry_id) {
          el.votes += vote.delta
          el.updated_at = new Date()
        }
      })
      state.roomState.votes.push(vote.entry_id)
      commit('setStatus', currentState)

      axios.post('client/vote', vote);

    },
    addSong({ commit }, track) {
      commit('addSong', track.track_id)

      // TODO: send changes to server and Vote!!!
      axios.post('client/add', {track_id: track.track_id})
    }
  },
  modules: {
  }
})
