<template>

    <component :is="layout">
        <router-view />

        <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">
            Eine neue Version ist verfügbar!
            <v-btn text @click="refreshApp">
                Neue Version laden
            </v-btn>
        </v-snackbar>
    </component>
</template>

<script>
    import HelloWorld from './components/HelloWorld';

    export default {
        name: 'App',
        computed: {
            layout() {
                return this.$route.meta.layout || 'AppLayout'
            }
        },
        data: function() {
          return {
              refreshing: false,
              registration: null,
              updateExists: false,
          }
        },
        mounted () {
            this.ping()
        },
        created() {
            // Listen for our custom event from the SW registration
            document.addEventListener('swUpdated', this.updateAvailable, { once: true })

            // Prevent multiple refreshes
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        },
        methods: {
            ping: async function() {
                const response = await axios.get('/data/ping')

                // console.log(response.data)

                window.setTimeout(this.ping, 10000)
            },
            // Store the SW registration so we can send it a message
            // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
            // To alert the user there is an update they need to refresh for
            updateAvailable(event) {
                this.registration = event.detail
                this.updateExists = true
            },

            // Called when the user accepts the update
            refreshApp() {
                this.updateExists = false
                // Make sure we only send a 'skip waiting' message if the SW is waiting
                if (!this.registration || !this.registration.waiting) return
                // send message to SW to skip the waiting and activate the new SW
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            },
        }
    };
</script>
