<template>
    <v-app>
        <v-app-bar
                app
                color="primary"
                dark
        >
            <div class="d-flex align-center">
                <v-img
                        alt="Vuetify Logo"
                        class="shrink mr-2"
                        contain
                        :src="logo"
                        transition="scale-transition"
                        width="40"
                />
            </div>

            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-app-bar>

        <v-main>
            <v-container fluid>
                <slot />

            </v-container>
        </v-main>

        <template>
            <v-bottom-navigation
                    color="teal"
                    grow
                    app
            >
                <v-btn to="/room">
                    <!--                    <span>Queue</span>-->

                    <v-icon>mdi-music</v-icon>
                </v-btn>

                <v-btn to="/search">
                    <!--                    <span>Search</span>-->

                    <v-icon>mdi-magnify</v-icon>
                </v-btn>

                <v-btn to="/about">
                    <!--                    <span>About</span>-->

                    <v-icon>mdi-information-outline</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </template>
    </v-app>
</template>

<script>
    import Logo from './../assets/logo.png';

    export default {
        name: "Pages",
        data: () => ({
            logo: Logo,
        }),
        computed: {
            title() {
                return this.$route.meta.title || 'NO TITLE'
            }
        }
    }
</script>

<style scoped>

</style>