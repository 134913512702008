import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'

import AppLayout from './layouts/App'
import StartLayout from './layouts/Start'
import PageLayout from './layouts/Pages'

Vue.component('AppLayout', AppLayout)
Vue.component('StartLayout', StartLayout)
Vue.component('PageLayout', PageLayout)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL
axios.defaults.withCredentials = true

Vue.mixin({
  methods: {
    canAdd() {
      return this.$store.state.roomState.access && this.$store.state.roomState.access.allowAdd == 1
    },
    canVote() {
      return this.$store.state.roomState.access && this.$store.state.roomState.access.allowVote == 1
    },
    addTrack(track) {
      if (this.canAdd()) {
        this.$store.dispatch('addSong', track)
      }
      else {
        alert("Der Raum erlaubt aktuelle keine neuen Songs")
      }
    },
    hasVoted(entry_id) {
      if (! this.$store.state.roomState.votes) {
        return true
      }

      return this.$store.state.roomState.votes.indexOf(entry_id) !== -1
    },
    isInQueue(track_id) {
      if (! this.$store.state.roomState.queue_short) {
        return false
      }

      return this.$store.state.roomState.queue_short.indexOf(track_id) !== -1
    },
    formatTime(miliseconds) {
      const duration = Math.floor(miliseconds / 1000)

      const minutes = Math.floor(duration / 60);
      let seconds = duration - (minutes * 60);

      if (seconds < 10) {
        seconds = seconds + "0"
      }

      return minutes + ":" + seconds
    },
    vote: function(entry_id, delta) {
      if (this.canVote()) {
        this.$store.dispatch('vote', {
          entry_id: entry_id,
          delta: delta
        })
      }
      else {
        alert("Der Raum erlaubt aktuelle keine Votes");
      }
    }
  },
  computed: {
    statusLoaded: function() {
      return this.$store.state.roomState.queue !== null
    },
    sortedQueue: function() {
      let queue = this.$store.state.roomState ? this.$store.state.roomState.queue : null

      if (! queue) {
        console.log('no queue yet')
        return []
      }

      queue.sort((a, b) => {
        if (a.locked_until && !b.locked_until) {
          return 1
        }
        if (b.locked_until && !a.locked_until) {
          return -1
        }

        return 0
      })

      queue.sort((a, b) => {
        if (a.votes !== b.votes) {
          return a.votes > b.votes ? -1 : 1
        }
        else {
          const dateA = new Date(a.updated_at)
          const dateB = new Date(b.updated_at);

          // console.log(dateA.getTime() - dateB.getTime())
          if (dateA.getTime() !== dateB.getTime()) {
            return dateA > dateB ? 1 : - 1
          }
          else {
            const createdA = new Date(a.created_at)
            const createdB = new Date(b.created_at);

            if (createdA.getTime() === createdB.getTime()) {
              return a.entry_id > b.entry_id ? 1 : -1
            }
            else {
              return createdA > createdB ? 1 : - 1
            }
          }
        }
      })

      return queue
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
