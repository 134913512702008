import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'StartLayout' },
    component: Home
  },
  {
    path: '/room',
    name: 'Room',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Room.vue')
    }
  },
  {
    path: '/search/:query?',
    name: 'Search-Main',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Search.vue')
    }
  },
  {
    path: '/artist/:artist_id',
    name: 'Search-Artist',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Artist.vue')
    },
  },
  {
    path: '/album/:album_id',
    name: 'Search-Album',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Album.vue')
    },
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Settings.vue')
    }
  },
  {
    path: '/add_song',
    name: 'ShareTarget',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ShareTarget.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
