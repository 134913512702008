<template>
    <v-app>
        <v-main>
            <v-container fluid>

                <slot />

            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: "Start"
    }
</script>

<style scoped>

</style>