<template>
    <div class="home text-center">
        <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                :src="logo"
                transition="scale-transition"
        />

        <input type="text" v-model="nickname" placeholder="Dein Name" class="nickname"/>
        <template v-if="hasHash">
            <v-btn
                    :disabled="nickname === ''"
                    color="primary"
                    elevation="4"
                    x-large
                    class="joinBtn"
                    @click="startJoin('hash')"
            >
                Raum beitreten
            </v-btn>
        </template>
        <template v-else>
            <input type="number" v-model="roomCode" placeholder="000000"/>
            <v-btn
                    :disabled="! codeValid || nickname === ''"
                    color="primary"
                    elevation="4"
                    x-large
                    class="joinBtn"
                    @click="startJoin('code')"
            >
                Raum beitreten
            </v-btn>
        </template>

        <v-dialog
                v-model="dialog"
                width="500"
        >
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Login mit Spotify
                </v-card-title>

                <v-card-text class="joinText">
                    Um ManyDJs zu verwenden musst du dich mit Spotify einloggen. Du benötigst hierfür kein Spotify
                    Premium!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="join"
                    >
                        Mit Spotify einloggen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    // @ is an alias to /src
    import HelloWorld from '@/components/HelloWorld.vue'
    import Logo from './../assets/logo.png';

    export default {
        name: 'Home',
        components: {
            HelloWorld
        },
        data: () => ({
            logo: Logo,
            hasHash: false,
            roomCode: '',
            codeValid: false,
            dialog: false,
            method: '',
            nickname: ''
        }),
        watch: {
            roomCode: function () {
                this.codeValid = this.roomCode >= 100000 && this.roomCode <= 999999
            }
        },
        mounted () {
            this.hasHash = !!this.$route.query.hash

            const nickInStorage = window.localStorage.getItem('nickname');

            if (nickInStorage) {
                this.nickname = nickInStorage;
            }

            const hashInStorage = window.localStorage.getItem('roomHash');

            if (this.$route.query.hash && this.$route.query.hash !== hashInStorage && hashInStorage) {
                if (window.confirm("Du bist zurzeit in einem anderen Raum. Möchtest du diesen verlassen?")) {
                    localStorage.removeItem('roomHash')
                    localStorage.removeItem('client_id')
                    document.cookie.split(";").forEach(function (c) {
                        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    });
                }
            }
            if (this.$route.query.notfound) {
                alert("Der von dir aufgerufene Raum existiert nicht oder hat seine Türen noch geschlossen...")
            }

            const client_id = window.localStorage.getItem('client_id');

            if (client_id) {
                this.$router.push('/room')
            }
            if (this.$route.query.client_id) {
                window.localStorage.setItem('client_id', this.$route.query.client_id);
                this.$router.push('/room')
            }
        },
        methods: {
            startJoin: function (method) {
                this.method = method

                this.dialog = true
            },
            join: function () {
                if (this.method === 'hash') {
                    this.joinRoom()
                } else {
                    this.joinByCode()
                }
            },
            joinByCode: async function () {
                const response = await axios.get('/client/getByCode?code=' + this.roomCode)

                if (response.data.error) {
                    alert("Der angegebene Raum existiert nicht!")
                } else {
                    const hash = response.data.hash;

                    window.localStorage.setItem('roomHash', hash)
                    window.localStorage.setItem('nickname', this.nickname)

                    window.location.href = process.env.VUE_APP_SERVER_URL + 'client/join?hash=' + hash + '&nickname=' + this.nickname
                }
                // window.localStorage.setItem('roomHash', this.$route.query.hash)
                //
                // window.location.href = process.env.VUE_APP_SERVER_URL + 'client/join?hash=' + this.$route.query.hash
            },
            joinRoom: async function () {
                window.localStorage.setItem('roomHash', this.$route.query.hash)
                window.localStorage.setItem('nickname', this.nickname)

                window.location.href = process.env.VUE_APP_SERVER_URL + 'client/join?hash=' + this.$route.query.hash + '&nickname=' + this.nickname
            },
            seePages: function () {
                this.$router.push('/pages')
            }
        }
    }
</script>

<style scoped lang="stylus">
    .joinBtn
        margin-top 50px

    input
        border 1px solid gray
        font-size 30px
        max-width 210px
        margin-top 10px
        text-align center
        letter-spacing 13px
        padding-left 13px

        &.nickname
            display block
            width 100%
            max-width 100%
            letter-spacing unset

        + .joinBtn
            margin-top 20px

    .joinText
        margin-top 20px
</style>