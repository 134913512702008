<template>
    <v-app>
        <v-app-bar
                app
                color="primary"
                dark
        >
            <div class="d-flex align-center">
                <v-img
                        alt="Vuetify Logo"
                        class="shrink mr-2"
                        contain
                        :src="logo"
                        transition="scale-transition"
                        width="40"
                />
            </div>

            <v-toolbar-title>{{ pageTitle }} - {{ roomName }}</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-app-bar>

        <v-main>
<!--            <v-container fluid>-->

                <slot />

<!--            </v-container>-->
        </v-main>

        <template>
            <v-bottom-navigation
                    color="teal"
                    grow
                    app
            >
                <v-btn to="/room">
                    <v-icon>mdi-music</v-icon>
                </v-btn>

                <v-btn to="/search" v-if="canAdd()">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>

                <v-btn to="/settings">
                    <v-icon>mdi-cogs</v-icon>
                </v-btn>

                <v-btn to="/about">
                    <v-icon>mdi-information-outline</v-icon>
                </v-btn>
            </v-bottom-navigation>
        </template>
    </v-app>
</template>

<script>
    import Logo from './../assets/logo.png';

    export default {
        name: "App",
        computed: {
            pageTitle () {
                return this.$store.state.pageTitle
            }
        },
        data: () => ({
            logo: Logo,
            client_id: null,
            roomName: ''
        }),
        methods: {
            updateStatus: async function () {
                try {
                    const response = await axios.get('/client/status')

                    this.roomName = response.data.name
                    document.title = this.roomName
                    this.$store.commit('setStatus', response.data)
                }
                catch(err) {
                    console.log(err)
                }

                window.setTimeout(this.updateStatus, 5000)
            }
        },
        mounted () {
            const clientId = localStorage.getItem('client_id')

            if (!clientId) {
                this.$router.push('/home')
            }

            this.client_id = clientId
            this.updateStatus()
        }
    }
</script>

<style scoped>

</style>